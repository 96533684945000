


































import { Component, Vue } from 'vue-property-decorator'
import { themeConfig } from '@/config/themeConfig'

@Component
export default class Support extends Vue {
  links = themeConfig.app.links
  wideLogo = themeConfig.app.wideLogo
}
